import React from "react";
import Cards from 'react-credit-cards';
import Swal from "sweetalert2";
import { connect } from 'react-redux';
import { payment, getVol, getUser, getCartItems, guestCartItems, getProvincias, getLoc, qrPaymentMODO, qrPaymentDNI } from "../../redux/actions";
import queryString from 'query-string';

import { provincias } from './provincias.js';

import 'react-credit-cards/es/styles-compiled.css';

import './paymentPage.css'
import { auth } from "../../firebase";
import Loader from "../loader";
import municipios from "./envios";
import { controlShipping } from "./controles";
import handleErrors from "./errors";

class PaymentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            method: '',
            phone: null,
            provincias: [],
            user: undefined,
            items: [],
            mp: window.mp,
            cvc: "",
            expiry: "",
            focus: "",
            name: "",
            number: "",
            docTypes: [],
            loading: true,
            manga: {},
            cart: false,
            item: "",
            shipping: {
                cliente: {
                    nombre: "",
                    dni: "",
                    email: ""
                },
                destino: {
                    cp: "",
                    calle: "",
                    numero: "",
                    provincia: "",
                    localidad: "",
                    piso_dpto: ""
                },
                price: 0
            },
            shipping_form: {
                type: "local",
                cp: false,
                form: false
            },
            total: 0,
            isShip: false,
            changed: false,
            locs: [],
            hasShip: false,
            retira: {
                nombre: '',
                dni: ''
            }
        }
    }

    createMPCard = () => {
        const cardForm = window.mp.cardForm({
            amount: `${this.state.total}`,
            //amount: `1`,
            autoMount: true,
            form: {
                id: "form-checkout",
                cardholderName: {
                    id: "form-checkout__cardholderName",
                    placeholder: "Titular de la tarjeta",
                },
                cardholderEmail: {
                    id: "form-checkout__cardholderEmail",
                    placeholder: "E-mail",
                },
                cardNumber: {
                    id: "form-checkout__cardNumber",
                    placeholder: "Número de la tarjeta",
                },
                cardExpirationDate: {
                    id: "form-checkout__cardExpirationDate",
                    placeholder: "Fecha de vencimiento (MM/YYYY)",
                },
                securityCode: {
                    id: "form-checkout__securityCode",
                    placeholder: "Código de seguridad",
                },
                installments: {
                    id: "form-checkout__installments",
                    placeholder: "Cuotas",
                },
                identificationType: {
                    id: "form-checkout__identificationType",
                    placeholder: "Tipo de documento",
                },
                identificationNumber: {
                    id: "form-checkout__identificationNumber",
                    placeholder: "Número de documento",
                },
                issuer: {
                    id: "form-checkout__issuer",
                    placeholder: "Banco emisor",
                },
            },
            callbacks: {
                onFormMounted: error => {
                    if (error) {
                        console.warn("Form Mounted handling error: ", error);
                        window.location.reload()
                    }
                },
                onSubmit: event => {
                    event.preventDefault();

                    const {
                        paymentMethodId: payment_method_id,
                        issuerId: issuer_id,
                        cardholderEmail: email,
                        amount,
                        token,
                        identificationNumber,
                        identificationType,
                    } = cardForm.getCardFormData();

                    const installments = document.getElementById("form-checkout__installments").value;

                    let data = {
                        purchase: {
                            items: [],
                            shipping: {},
                            total: this.state.total
                        },
                        payer: {
                            name: document.getElementById("form-checkout__cardholderName").value,
                            email: document.getElementById("form-checkout__cardholderEmail").value,
                            dni: document.getElementById("form-checkout__identificationNumber").value,
                            phone: this.state.phone
                        },
                        userID: ""
                    }

                    if (auth.currentUser) {
                        data.userID = auth.currentUser.uid
                    } else {
                        data.userID = null
                    }

                    if (this.state.shipping_form.type === "local") {
                        data.shipping = null;
                        data.retira = this.state.retira
                    } else {
                        data.retira = null
                        data.shipping = this.state.shipping;
                    }

                    if (!this.state.items[0]) {
                        data.purchase.items.push(this.state.manga);
                        data.purchase.items[0].cant = 1;
                    } else {
                        data.purchase.items = this.state.items;
                    }

                    const body = {
                        token,
                        issuer_id,
                        payment_method_id,
                        transaction_amount: Number(amount),
                        installments: Number(installments),
                        description: "Descripción del producto",
                        payer: {
                            email,
                            identification: {
                                type: identificationType,
                                number: identificationNumber,
                            },
                        },
                        user: "anónimo",
                        compra: data
                    }

                    this.props.payment(body, data);

                    Swal.fire({
                        titleText: "Tu pago está siendo procesado...",
                        showConfirmButton: false,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })

                },
                //ERRORES
                onPaymentMethodsReceived: (error, paymentMethods) => {
                    if (error) {
                        return handleErrors(error);
                    }
                },
                onIssuersReceived: (error, issuers) => {
                    if (error) {
                        return handleErrors(error);
                    }
                },
                onInstallmentsReceived: (error, installments) => {
                    if (error) {
                        return handleErrors(error);
                    }
                },
                onCardTokenReceived: (error, token) => {
                    if (error) {
                        return handleErrors(error);
                    }
                },

            },
        })
    }

    getDNIs = async () => {
        const docTypes = await window.mp.getIdentificationTypes();

        this.setState({
            docTypes: docTypes
        })
    }

    componentDidMount() {
        this.getDNIs();

        /*
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
          // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        const cart = params.cart; // "some_value"
        const url = params.item;
        */

        const url = queryString.parse(window.location.hash).item;
        const obj = queryString.parse(window.location.hash);
        let cart;

        for (let key in obj) {
            if (key !== "item") {
                cart = obj[key];
            }
        }

        if (cart === "true") {
            this.setState({
                cart: true
            })
        } else {
            const number = window.location.hash.split('&')[2]
            const special = window.location.hash.split('&')[3]
            const name = url + '&' + number + '&' + special
            this.setState({
                cart: false,
                item: name
            })
        }

    }

    handleInputFocus = (e) => {
        if (e.target.id === "form-checkout__cardNumber") {
            this.setState({
                focus: "number"
            })
        } else if (e.target.id === "form-checkout__cardholderName") {
            this.setState({
                focus: "name"
            })
        } else if (e.target.id === "form-checkout__securityCode") {
            this.setState({
                focus: "cvc"
            })
        } else if (e.target.id === "form-checkout__cardExpirationDate") {
            this.setState({
                focus: "expiry"
            })
        }
    }

    handleInputChange = (e) => {
        if (e.target.id === "form-checkout__cardNumber") {
            this.setState({
                number: e.target.value
            })
        } else if (e.target.id === "form-checkout__cardholderName") {
            this.setState({
                name: e.target.value
            })
        } else if (e.target.id === "form-checkout__securityCode") {
            this.setState({
                cvc: e.target.value
            })
        } else if (e.target.id === "form-checkout__cardExpirationDate") {
            this.setState({
                expiry: e.target.value
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        auth.onAuthStateChanged(() => {
            if (!this.state.changed) {
                if (auth.currentUser) {
                    this.setState({
                        user: auth.currentUser.uid
                    })
                }

                this.setState({
                    changed: true
                }, () => {
                    this.setState({
                        provincias: provincias
                    })
                })
            }
        })

        if (prevState.provincias !== this.state.provincias) {
            if (this.state.cart) {
                if (this.state.user) {
                    this.props.getUser(this.state.user)
                } else {
                    const storage = JSON.parse(localStorage.getItem('cart'));
                    if (storage) {
                        this.props.guestCartItems(storage.items)
                    } else {
                        this.setState({
                            cart: []
                        }, () => {
                            this.setState({
                                total: 0
                            }, () => {
                                this.setState({
                                    loading: false
                                })
                            })
                        })
                    }
                }
            } else {
                this.props.getVol(this.state.item)
            }
        }

        if (prevProps.state.user !== this.props.state.user) {
            this.setState({
                user: this.props.state.user
            }, () => {
                if (this.state.cart) {
                    this.props.getCartItems(auth.currentUser.uid)
                }
            })
        }

        if (prevProps.state.cart !== this.props.state.cart) {
            this.setState({
                items: this.props.state.cart
            }, () => {
                let total = 0;
                for (let i = 0; i < this.state.items.length; i++) {
                    total += (parseInt(this.state.items[i].precio) * this.state.items[i].cant)
                }

                this.setState({
                    total: total
                }, () => {
                    this.setState({
                        loading: false
                    })
                })
            })
        }

        if (prevProps.state.vol !== this.props.state.vol) {
            this.setState({
                manga: this.props.state.vol,
                total: this.props.state.vol.precio
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status === "success") {
                Swal.fire({
                    title: "Compra aprobada con éxito!",
                    icon: "success"
                })
                    .then(() => {
                        Swal.fire({
                            text: "Revisá tu casilla de correo para encontrar toda la información sobre tu compra",
                        })
                            .then(() => {
                                window.location.href = "/#/"
                            })
                    })
            } else {
                handleErrors(this.props.state.status)
                    .then(() => {
                        window.location.reload()
                    })
            }
        }

        if (prevProps.state.loc !== this.props.state.loc) {
            this.setState({
                locs: this.props.state.loc
            }, () => {
                document.getElementById("input-localidad").disabled = false;
            })
        }

        if (prevState.isShip !== this.state.isShip) {
            this.createMPCard()
            console.log('pasamos a comrpar')
            console.log(this.state.items)
        }
    }

    getTitle = (item) => {
        if (item.figura) {
            return `${item.title}`
        } else {
            if (item.libro) {
                if (item.subtitulo) {
                    return `${item.serie} ${item.numero}: ${item.subtitulo}`
                } else {
                    return `${item.serie} ${item.numero}`
                }
            } else if (item.coleccion) {
                return `${item.nombre}`
            } else {
                if (item.special) {
                    return `${item.serie} #${item.numero} - ${item.comment}`
                } else {
                    return `${item.serie} #${item.numero}`
                }
            }
        }
    }

    listItems = () => {
        if (!this.state.items[0]) {
            return (
                <span className="list-item-container">
                    <p className="items-total">
                        TOTAL: ${this.state.manga.precio}.-
                    </p>
                    <span className="list-item">
                        <h5 className="item-title">
                            {this.getTitle(this.state.manga)}
                        </h5>
                        <p className="item-price">
                            {`$${this.state.manga.precio}`}
                        </p>
                        <p className="item-cant">
                            Cant. x1
                        </p>
                    </span>
                    <br />
                    <span className="list-item">
                        <h5 className="item-title">
                            {`Envío`}
                        </h5>
                        <p className="item-price">
                            {`$${this.state.shipping.price}`}
                        </p>
                    </span>
                </span>
            )
        } else {
            return (
                <span className="list-item-container">
                    <p className="items-total">
                        TOTAL: ${this.state.total}.-
                    </p>
                    <br />
                    <br />
                    <span className="list-item">
                        {
                            this.state.items.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <h5 className="item-title">
                                            {this.getTitle(item)}
                                        </h5>
                                        <p className="item-price">
                                            {`$${item.precio}`}
                                        </p>
                                        <p className="item-cant">
                                            {`Cant. x${item.cant}`}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </span>
                    <br />
                    <span className="list-item">
                        <h5 className="item-title">
                            {`Envío`}
                        </h5>
                        <p className="item-price">
                            {`$${this.state.shipping.price}`}
                        </p>
                    </span>
                </span>
            )
        }
    }

    handleSelect = () => {
        if (document.getElementById("domicilio").selected) {
            this.setState({
                shipping_form: {
                    type: "domicilio",
                    form: false,
                    cp: true
                }
            })
        } else if (document.getElementById("local").selected) {
            this.setState({
                shipping_form: {
                    type: "local",
                    form: false,
                    cp: true
                }
            })
        }
    }

    shippingStyle = (e) => {
        if (this.state.shipping_form.type === 'local' && e === 'envio') {
            return { "display": "none" }
        } else if (this.state.shipping_form.type === 'domicilio' && e === 'local') {
            return { "display": "none" }
        } else {
            return { "display": "flex" }
        }
    }

    goToPay = (e) => {
        e.preventDefault();
        window.scroll({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }

    payButtonStyle = () => {
        if (window.screen.width < 450) {
            return { "display": "flex" }
        } else {
            return { "display": "none" }
        }
    }

    calculateShipping = (e) => {
        e.preventDefault();

        if (this.state.shipping_form.type === "local") {
            let retira = document.getElementById('shipping-name').value
            let dni = document.getElementById('shipping-dni').value
            let phone = document.getElementById("input-cellphone").value

            var error = false;
            var message;

            if (retira.length === 0) {
                error = true;
                message = 'Ingrese un nombre válido!'
                Swal.fire({
                    icon: "error",
                    text: message
                })
                    .then(() => {
                        return 0;
                    })
            } else if (dni.length === 0) {
                error = true;
                message = 'Ingrese un DNI válido!'
                Swal.fire({
                    icon: "error",
                    text: message
                })
                    .then(() => {
                        return 0;
                    })
            } else if (phone.length === 0) {
                error = true;
                message = 'Ingrese un teléfono válido!'
                Swal.fire({
                    icon: "error",
                    text: message
                })
                    .then(() => {
                        return 0;
                    })
            } else {
                this.setState({
                    retira: {
                        nombre: retira,
                        dni: dni
                    },
                    phone: phone
                }, () => {
                    this.setState({
                        isShip: true
                    })
                })
            }
        } else {
            let precio;
            let loc = document.getElementById("input-localidad").value;
            const prov = document.getElementById("input-provincia").value;
            let loc_obj;

            let cordon = false;

            this.state.locs.map((e) => {
                if (e.nombre === loc) {
                    loc_obj = e;
                }
            })

            if (prov === "Ciudad Autónoma de Buenos Aires") {
                cordon = true;
            } else if (prov === "Buenos Aires") {
                municipios.map((e) => {
                    if (e.toLocaleLowerCase() === loc_obj.municipio_nombre.toLocaleLowerCase()) {
                        cordon = true;
                    }
                })
            } else {
                cordon = false;
            }

            if (cordon) {
                precio = 4500
            } else {
                precio = 8000
            }

            if (this.state.total >= 35000) {
                precio = 0;
            }

            let _loc;

            if (!loc_obj) {
                return Swal.fire({
                    icon: "error",
                    title: "Ingrese una localidad válida!"
                })
            } else {
                _loc = loc_obj.nombre;
            }

            const cp = document.getElementById("input-cp").value;
            const calle = document.getElementById("input-calle").value;
            const numero = document.getElementById("input-numero").value;
            const dpto = document.getElementById("input-piso").value;
            const nombre = document.getElementById("input-shipping-name").value;
            const email = document.getElementById("input-shipping-email").value;
            const dni = document.getElementById("input-shipping-dni").value;
            const phone = document.getElementById("input-cellphone-ship").value

            const control = controlShipping(prov, _loc, cp, calle, numero, dpto, nombre, email, dni);

            if (phone.length < 8) {
                return Swal.fire({
                    icon: "error",
                    title: "Ingrese un teléfono válido!"
                })
            }

            if (control.error.has) {
                return Swal.fire({
                    icon: "error",
                    title: control.error.messeage
                })
            } else {
                this.setState({
                    shipping: {
                        cliente: {
                            nombre: nombre,
                            dni: dni,
                            email: email
                        },
                        destino: {
                            cp: cp,
                            calle: calle,
                            numero: numero,
                            provincia: prov,
                            localidad: _loc,
                            piso_dpto: dpto
                        },
                        price: precio
                    },
                    isShip: true,
                    total: this.state.total + precio,
                    phone: phone
                })
            }

        }


    }

    showBuy = () => {
        if (this.state.isShip) {
            return { "display": "flex" }
        } else {
            return { "display": "none" }
        }
    }

    showShipping = () => {
        if (this.state.isShip) {
            return { "display": "none" }
        } else {
            return { "display": "flex" }
        }
    }

    getLocs = (e) => {
        if (e.target.value.length >= 3) {
            document.getElementById("input-localidad").disabled = true;
            this.props.getLoc(document.getElementById("input-provincia").value, e.target.value);
        }
    }

    testPurchase = (e) => {
        e.preventDefault()

        let data = {
            purchase: {
                items: [],
                shipping: {},
                total: this.state.total
            },
            payer: {
                name: document.getElementById("form-checkout__cardholderName").value,
                email: document.getElementById("form-checkout__cardholderEmail").value,
                dni: document.getElementById("form-checkout__identificationNumber").value
            },
            userID: ""
        }

        if (auth.currentUser) {
            data.userID = auth.currentUser.uid
        } else {
            data.userID = null
        }

        if (this.state.shipping_form.type === "local") {
            data.shipping = null;
            data.retira = this.state.retira
        } else {
            data.retira = null
            data.shipping = this.state.shipping;
        }

        if (!this.state.items[0]) {
            data.purchase.items.push(this.state.manga);
            data.purchase.items[0].cant = 1;
        } else {
            data.purchase.items = this.state.items;
        }

        this.props.payment(data);

        Swal.fire({
            titleText: "Tu pago está siendo procesado...",
            showConfirmButton: false,
        })
    }

    changeMethod = (e) => {
        this.setState({
            method: e.target.value
        })
    }

    getMethodStyle = (e) => {
        if (e === this.state.method) return { "display": "flex" }
        return { "display": "none" }
    }

    submitQR = (e) => {
        //CHEQUEAR DATOS
        e.preventDefault();

        let data = {
            purchase: {
                items: [],
                shipping: {},
                total: this.state.total
            },
            payer: {
                name: document.getElementById("form-checkout__qrName").value,
                email: document.getElementById("form-checkout__qrEmail").value,
                dni: document.getElementById("form-checkout__qrdni").value,
                phone: this.state.phone
            },
            userID: ""
        }

        if (auth.currentUser) {
            data.userID = auth.currentUser.uid
        } else {
            data.userID = null
        }

        if (this.state.shipping_form.type === "local") {
            data.shipping = null;
            data.retira = this.state.retira
        } else {
            data.retira = null
            data.shipping = this.state.shipping;
        }

        if (!this.state.items[0]) {
            data.purchase.items.push(this.state.manga);
            data.purchase.items[0].cant = 1;
        } else {
            data.purchase.items = this.state.items;
        }

        this.props.qrPaymentMODO(data)

        Swal.fire({
            titleText: "Procesando compra...",
            showConfirmButton: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        })
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="payment-page-container">
                    <div className="shipping-container" style={this.showShipping()}>
                        <p className="shipping-title">
                            ENVÍOS
                        </p>
                        <div className="shipping-space-container">
                            <span className="select-shipping">
                                <select className="payment-input" onChange={this.handleSelect}>
                                    <option id="local">Retiro en local</option>
                                    <option id="domicilio">Envío a domicilio</option>
                                </select>
                                <p className="no-cp">Recordá que en compras superiores a $35.000, el envío es gratis!</p>
                            </span>
                            <span className="shipping-main-container" style={this.shippingStyle('local')}>
                                <span className="shipping-form-container">
                                    <form className="shipping-form" onSubmit={this.calculateShipping}>

                                        <input
                                            className="payment-input"
                                            id="shipping-name"
                                            type="text"
                                            placeholder="Quién retira..."
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="DNI"
                                            id="shipping-dni"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="Número de celular"
                                            id="input-cellphone"
                                            type="number"
                                        />
                                    </form>
                                </span>
                            </span>
                            <span className="shipping-main-container" style={this.shippingStyle('envio')}>
                                <span className="shipping-form-container">
                                    <form className="shipping-form" onSubmit={this.calculateShipping}>

                                        <select
                                            className="payment-input"
                                            placeholder="Provincia"
                                            id="input-provincia"
                                        >
                                            {
                                                this.state.provincias.map((e) => {
                                                    return <option key={e}>{e}</option>
                                                })
                                            }
                                        </select>
                                        <input
                                            className="buscar-localidad"
                                            id="search-loc"
                                            type="text"
                                            placeholder="Empezá a escribir tu localidad..."
                                            onChange={this.getLocs}
                                        />

                                        <select
                                            className="payment-input"
                                            placeholder="Localidad"
                                            id="input-localidad"
                                        >
                                            {
                                                this.state.locs.map((e) => {
                                                    return (
                                                        <option key={e.nombre}>
                                                            {e.nombre}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>

                                        <input
                                            className="payment-input"
                                            placeholder="Código Postal"
                                            id="input-cp"
                                        />
                                        <p className="no-cp" style={{ "text-align": "center" }}>
                                            No conocés tu Código Postal? Averigualo <a href="https://www.correoargentino.com.ar/formularios/cpa" rel="noreferrer" target="_blank"><b>acá</b></a>
                                        </p>

                                        <input
                                            className="payment-input"
                                            placeholder="Calle"
                                            id="input-calle"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="Número"
                                            id="input-numero"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="Piso/dpto."
                                            id="input-piso"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="Nombre completo del destinatario"
                                            id="input-shipping-name"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="Email del destinatario"
                                            id="input-shipping-email"
                                        />

                                        <input
                                            className="payment-input"
                                            placeholder="DNI del destinatario"
                                            id="input-shipping-dni"
                                        />
                                        <input
                                            className="payment-input"
                                            placeholder="Número de celular"
                                            id="input-cellphone-ship"
                                            type="number"
                                        />
                                    </form>
                                </span>
                            </span>
                            <button
                                className="payment-input"
                                id="shipping-button"
                                onClick={this.calculateShipping}
                            >Proceder al pago</button>
                        </div>
                    </div>
                    <div className="payment-page" style={this.showBuy()}>
                        <button className="payment-input" id="back-s" onClick={() => { window.location.reload() }}>Volver al envío</button>
                        <div className="payment-method">
                            <h2 className="payment-method-title">
                                Método de pago
                            </h2>
                            <select className="payment-method-select" onChange={this.changeMethod}>
                                <option value="default" selected={true} disabled={true}>Elegir</option>
                                <option value="card">Tarjeta de crédito/débito</option>
                                <option value="qr-modo">QR (MODO, Galicia, MP)</option>
                                <option value="qr-dni">QR (DNI)</option>
                            </select>
                        </div>
                        <div className="payment-main-container-qr" style={this.getMethodStyle('qr-modo')}>
                            <h2 className="warning">Leer con atención!</h2>
                            <p className="qr-description">
                                Para pagar con QR, completar los datos de pago. Luego, se le enviará un mail con el QR para 
                                poder efectuar el pago y el ID de la compra. Una vez realizado, enviar a thebookhall.lyc@gmail.com 
                                el comprobante de pago junto con el ID de compra.
                            </p>
                            <form id="form-checkout-qr" onSubmit={this.submitQR}>
                                <input
                                    type="text"
                                    name="name"
                                    id="form-checkout__qrName"
                                    placeholder="Nombre completo"
                                    className="payment-input"
                                />

                                <input
                                    type="email"
                                    name="cardholderEmail"
                                    id="form-checkout__qrEmail"
                                    placeholder="E-mail"
                                    className="payment-input"
                                />

                                <input
                                    type="text"
                                    name="identificationNumber"
                                    id="form-checkout__qrdni"
                                    placeholder="Número de documento"
                                    className="payment-input"
                                />
                                <button type="submit" id="qrsubmit" className="payment-button">Continuar</button>
                            </form>
                        </div>
                        <div className="payment-main-container-qr" style={this.getMethodStyle('qr-dni')}>
                            <h2 className="warning">Leer con atención!</h2>
                            <p className="qr-description">
                                Para pagar con QR, completar los datos de pago. Luego, se le enviará un mail con el QR para 
                                poder efectuar el pago y el ID de la compra. Una vez realizado, enviar a thebookhall.lyc@gmail.com 
                                el comprobante de pago junto con el ID de compra.
                            </p>
                            <form id="form-checkout-qr" onSubmit={this.submitQR}>
                                <input
                                    type="text"
                                    name="name"
                                    id="form-checkout__qrName"
                                    placeholder="Nombre completo"
                                    className="payment-input"
                                />

                                <input
                                    type="email"
                                    name="cardholderEmail"
                                    id="form-checkout__qrEmail"
                                    placeholder="E-mail"
                                    className="payment-input"
                                />

                                <input
                                    type="text"
                                    name="identificationNumber"
                                    id="form-checkout__qrdni"
                                    placeholder="Número de documento"
                                    className="payment-input"
                                />
                                <button type="submit" id="qrsubmit" className="payment-button">Continuar</button>
                            </form>
                        </div>
                        <div className="payment-main-container" style={this.getMethodStyle('card')}>
                            <span className="card-container">
                                <Cards
                                    cvc={this.state.cvc}
                                    expiry={this.state.expiry}
                                    focused={this.state.focus}
                                    name={this.state.name}
                                    number={this.state.number}
                                />
                            </span>
                            <form id="form-checkout">
                                <input
                                    type="text"
                                    maxLength="16"
                                    name="number"
                                    id="form-checkout__cardNumber"
                                    placeholder="Número de la tarjeta"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    className="payment-input"
                                />

                                <input
                                    type="text"
                                    maxLength="7"
                                    name="expiry"
                                    id="form-checkout__cardExpirationDate"
                                    placeholder="Fecha de vencimiento (MM/YYYY)"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    className="payment-input"
                                />

                                <input
                                    type="text"
                                    name="name"
                                    id="form-checkout__cardholderName"
                                    placeholder="Titular de la tarjeta"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    className="payment-input"
                                />

                                <input
                                    type="email"
                                    name="cardholderEmail"
                                    id="form-checkout__cardholderEmail"
                                    placeholder="E-mail"
                                    className="payment-input"
                                />

                                <input
                                    className="payment-input"
                                    type="text"
                                    maxLength="5"
                                    name="cvc"
                                    id="form-checkout__securityCode"
                                    placeholder="Código de seguridad"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />

                                <select
                                    name="issuer"
                                    id="form-checkout__issuer"
                                    className="payment-input"
                                >

                                </select>

                                <select
                                    name="identificationType"
                                    id="form-checkout__identificationType"
                                    className="payment-input"
                                >
                                    {
                                        this.state.docTypes.map((e) => {
                                            return (
                                                <option key={e.id}>
                                                    {e.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>

                                <input
                                    type="text"
                                    name="identificationNumber"
                                    id="form-checkout__identificationNumber"
                                    placeholder="Número de documento"
                                    className="payment-input"
                                />

                                <select
                                    name="installments"
                                    id="form-checkout__installments"
                                    className="payment-input"
                                >

                                </select>
                                <button type="submit" id="form-checkout__submit" className="payment-button">Pagar</button>
                            </form>
                        </div>
                        {this.listItems()}
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    payment,
    getVol,
    getUser,
    getCartItems,
    guestCartItems,
    getProvincias,
    getLoc,
    qrPaymentMODO,
    qrPaymentDNI
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(PaymentPage);